var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ax-table',{attrs:{"headers":_vm.batchesHeaders,"active-headers":_vm.activeHeaders,"items":_vm.batches,"column-select":true,"options":_vm.tableState,"item-key":"id","footer-props":_vm.batchesFooter,"server-items-length":_vm.serverItemsLength},on:{"update:options":function($event){_vm.tableState=$event},"columns-updated":_vm.updateColumnsState},scopedSlots:_vm._u([{key:"item.source",fn:function(ref){
var item = ref.item;
return [(
        item.status === _vm.BatchStatuses.AwaitingApproval ||
        item.status === _vm.BatchStatuses.Approved ||
        item.status === _vm.BatchStatuses.Ready
      )?_c('router-link',{attrs:{"to":{
        path: ("/manage/remediations/" + (item.id)),
        query: { o: _vm.orgId },
      }}},[_vm._v(" "+_vm._s(item.source)+" ")]):_c('div',[_vm._v(_vm._s(item.source))])]}},{key:"item.created_by_user",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.configuration_id ? _vm.$t('tasks.remediations.generatedByThirdParty') : item.created_by_user.email)+" ")]}},{key:"item.total_tasks",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$tc('integrations.AVR.remediationsCount', item.total_tasks)))])]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"tw-flex tw-items-center"},[(item.status === _vm.BatchStatuses.Building)?_c('ax-loader-bars'):_vm._e(),_vm._v(" "+_vm._s(_vm.getBatchStatusText(item.status))+" "),(item.status === _vm.BatchStatuses.Error)?_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('span',_vm._g({staticClass:"tw-ml-2 ax-text-teal",attrs:{"role":"button","tabindex":"0"}},on),[_c('v-icon',{attrs:{"role":"img","aria-hidden":"false","aria-label":"help text - batch status","dense":"","color":"teal"}},[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$ts('genericErrorMsg'))+" ")])]):_vm._e()],1)]}},{key:"item.report_source",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.report_source)+" "),(_vm.isAutomatedRemediation(item.configuration_id))?_c('v-badge',{attrs:{"inline":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(_vm._s(_vm.$t('integrations.AVR.badge')))]},proxy:true}],null,true)}):_vm._e()]}},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatUTCToZonedTime(item.updated_at))+" ")]}},{key:"item.next_run",fn:function(ref){
      var item = ref.item;
return [(
        _vm.configurations.get(item.configuration_id) &&
        _vm.configurations.get(item.configuration_id).enabled
      )?_c('span',[_vm._v(" "+_vm._s(_vm.getNextRunTime())+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.canUseAVR && _vm.isAutomatedRemediation(item.configuration_id))?_c('ax-table-row-actions-column',[_c('v-list-item',{on:{"click":function($event){return _vm.runConfiguration(item.configuration_id)}}},[_vm._v(" "+_vm._s(_vm.$t('integrations.AVR.fetchLatest'))+" ")]),(_vm.configurations.get(item.configuration_id))?[(_vm.configurations.get(item.configuration_id).enabled)?_c('v-list-item',{on:{"click":function($event){return _vm.setConfigurationEnabled(item.configuration_id, false)}}},[_vm._v(" "+_vm._s(_vm.$t('integrations.AVR.disableScheduling'))+" ")]):_c('v-list-item',{on:{"click":function($event){return _vm.setConfigurationEnabled(item.configuration_id, true)}}},[_vm._v(" "+_vm._s(_vm.$t('integrations.AVR.enableScheduling'))+" ")])]:_vm._e()],2):_vm._e()]}},{key:"actions-left",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-between tw-items-end tw-w-full tw-mb-3"},[_c('ax-import-tasks-action',{attrs:{"location":"Imported Batches Home","data-test-id":"batches-table"}}),_c('ax-timestamp',{attrs:{"time-stamp":_vm.loadTimestamp}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }